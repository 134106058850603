import {
  ref, readonly, provide, inject,
} from 'vue';

const showSymbol = Symbol('');
const hideSymbol = Symbol('');
const toggleSymbol = Symbol('');

export function createSidebar() {
  const isShown = ref(false);

  const show = () => { isShown.value = true; };
  const hide = () => { isShown.value = false; };
  const toggle = () => { isShown.value = !isShown.value; };

  provide(showSymbol, show);
  provide(hideSymbol, hide);
  provide(toggleSymbol, toggle);

  return {
    isShown: readonly(isShown),
    show,
    hide,
    toggle,
  };
}

export function useSidebar() {
  return {
    show: inject(showSymbol) ?? (() => false),
    hide: inject(hideSymbol) ?? (() => false),
    toggle: inject(toggleSymbol) ?? (() => false),
  };
}

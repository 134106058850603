<template>
  <svg width="1em" height="1em" viewBox="0 0 32 32"
       fill="none"
       stroke="currentColor"
       stroke-linecap="round"
       stroke-linejoin="round"
       stroke-width="2px"
       xmlns="http://www.w3.org/2000/svg">
    <g>
      <line class="cls-1" x1="21" x2="12" y1="16" y2="25"/>
      <line class="cls-1" x1="12" x2="21" y1="7" y2="16"/>
    </g>
  </svg>
</template>
<script setup>
</script>

<template>
  <div class="templates">
    <div class="templates__tabs">
      <div class="tabs">
        <div class="tabs__tab"
             :class="{'tabs__tab_active': currentTab === 'public'}"
             @click="currentTab = 'public'">
          <GlobeIcon/>
          <span>Каталог</span>
        </div>
        <div class="tabs__tab"
             :class="{'tabs__tab_active': currentTab === 'personal'}"
             @click="currentTab = 'personal'">
          <UserIcon/>
          <span>Личные</span>
        </div>
        <div class="tabs__tab"
             :class="{'tabs__tab_active': currentTab === 'favorite'}"
             @click="currentTab = 'favorite'">
          <FavoriteIcon/>
          <span>Избранные</span>
        </div>
        <div class="tabs__tab"
             :class="{'tabs__tab_active': currentTab === 'edit' && !template}"
             @click="editTemplate()">
          <PlusIcon/>
          <span>Создать</span>
        </div>
        <div class="tabs__tab" @click="$emit('close')">
          <CancelIcon/>
          <span>Закрыть</span>
        </div>
      </div>
    </div>
    <Toolbar v-if="currentTab !== 'edit'" class="templates__toolbar">
      <template #start>
        <Choise
          empty-text="Все категории"
          v-model="filterCategory"
          :items="categories"
          :item-key="(i) => i.id"
          :item-label="(i) => i.title"
          :pt="{menu: {position: 'bottom left'}}" />
      </template>
      <template #end>
        <div class="templates__search">
          <SearchIcon />
          <input type="text" placeholder="поиск" v-model.trim="filterText" />
        </div>
      </template>
    </Toolbar>
    <ScrollPanel class="templates__content">
      <TemplateEdit v-if="currentTab === 'edit'" :template="template" :key="template?.id"
                    @close="currentTab = 'personal'"/>
      <TemplateList v-else :templates="displayTemplates"
                    :can-edit="currentTab === 'personal'"
                    @select="$emit('select', $event)"
                    @edit="editTemplate($event)"
                    @remove="removeTemplate($event)"
                    @favorite="favoriteTemplate($event)"
                    @public="publicTemplate($event)"/>
    </ScrollPanel>
    <span class="templates__close button" @click="$emit('close')">
          <CancelIcon/>
      </span>
  </div>
</template>

<script setup>
import {
  computed, defineEmits, ref, watch,
} from 'vue';
import api from '@/utils/api';
import useTemplateStore from '@/store/template';
import CancelIcon from '@/components/icons/CancelIcon.vue';
import PlusIcon from '@/components/icons/PlusIcon.vue';
import UserIcon from '@/components/icons/UserIcon.vue';
import FavoriteIcon from '@/components/icons/FavoriteIcon.vue';
import TemplateList from '@/components/TemplateList.vue';
import TemplateEdit from '@/components/TemplateEdit.vue';
import GlobeIcon from '@/components/icons/GlobeIcon.vue';
import ScrollPanel from '@/components/ScrollPanel.vue';
import Toolbar from '@/components/Toolbar.vue';
import Choise from '@/components/Choise.vue';
import SearchIcon from '@/components/icons/SearchIcon.vue';

defineEmits(['close', 'select']);

const templateStore = useTemplateStore();
const currentTab = ref('public');

const categories = computed(() => [{ id: 0, title: 'Все категории' }].concat(templateStore.categories ?? []));
const filterCategory = ref(categories.value[0]);
const filterText = ref('');
templateStore.loadCategories(true);

const templates = ref([]);
const loadTemplates = async () => {
  templates.value = [];
  if (currentTab.value === 'edit') {
    return;
  }
  templates.value = await api.get('template/list', { params: { type: currentTab.value } });
};

const displayTemplates = computed(() => {
  const categoryId = filterCategory.value.id;
  const text = filterText.value.toLowerCase();
  return templates.value.filter((t) => {
    if (categoryId && !t.categoryIds.includes(categoryId)) {
      return false;
    }
    if (text) {
      const templateText = `${t.title}\n${t.description}\n${t.tags}`.toLowerCase();
      return templateText.includes(text);
    }
    return true;
  });
});

const template = ref(null);

const editTemplate = (t) => {
  template.value = t;
  currentTab.value = 'edit';
};

const updateTemplate = (t, fileds) => {
  const index = templates.value.findIndex((a) => a.id === t.id);
  if (index !== -1) {
    Object.assign(templates.value[index], fileds);
  }
};

const removeTemplate = async (t, local) => {
  if (local !== true) {
    try {
      await api.post('template/remove', { id: t.id });
    } catch (e) {
      console.error(e);
    }
  }
  const index = templates.value.findIndex((a) => a.id === t.id);
  if (index !== -1) {
    templates.value.splice(index, 1);
  }
};

const favoriteTemplate = async (t) => {
  try {
    await api.post('template/favorite', { id: t.id, favorite: !t.favorite });
  } catch (e) {
    console.error(e);
  }
  if (t.favorite && currentTab.value === 'favorite') {
    removeTemplate(t, true);
  } else {
    updateTemplate(t, { favorite: !t.favorite });
  }
};

const publicTemplate = async (t) => {
  try {
    await api.post('template/public', { id: t.id, public: !t.public });
  } catch (e) {
    console.error(e);
  }
  if (t.public && currentTab.value === 'public') {
    removeTemplate(t, true);
  } else {
    updateTemplate(t, { public: !t.public });
  }
};

watch(currentTab, () => {
  loadTemplates();
});
loadTemplates();

</script>
<style lang="scss">
@import "@/assets/variables";
.templates {
  height: 100%;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  //align-items: center;
  position: relative;

  &__tabs {
    width: 100%;
    font-size: 14px;
    .tabs__tab span {
      display: none;
    }
  }

  &__toolbar{
    box-sizing: border-box;
    padding: 1em 1em .5em;
    width: 100%;
    max-width: $contentNarrowWidth;
    margin: 0 auto;
  }

  &__search{
    display: flex;
    align-items: center;
    gap: .5em;
    padding: .25em .5em;
    background: #f8f8f8;
    border-radius: 3px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    input{
      outline: none;
      border: none;
      background: inherit;
      color: inherit;
      width: 150px;
    }
  }

  &__content{
    width: 100%;
    height: 0;
    flex-grow: 1;
    box-sizing: border-box;
    padding: .5em 1em 2.8em;
  }

  &__close {
    box-shadow: 0 0 5px rgba(0, 0, 0, .15);
    padding: .3em 2.5em;
    border-radius: 1.6em 1.6em 0 0;
    position: absolute;
    bottom: 0;
    transition: padding-bottom .3s ease;
    align-self: center;

    &:hover {
      padding-bottom: .5em;
    }
  }
}

@media all and (min-width: 640px) {
  .templates__tabs .tabs__tab span {
    display: inline;
  }
}
</style>

<template>
  <div class="chat-links">
        <Button @click="$emit('regenerate')" v-if="regenerate" severity="">
          <span>{{ regenerateText }}</span>
          <span v-if="regenerateTimeout > 0"> (через {{ regenerateTimeout }} сек)</span>
        </Button>
    <router-link class="link" to="/payment" v-if="upgrade">{{ upgradeText }}</router-link>
  </div>
</template>

<script setup>
import {
  defineEmits, defineExpose, ref, watch,
} from 'vue';
import Button from '@/components/Button.vue';

const emit = defineEmits(['regenerate']);

defineProps({
  regenerate: Boolean,
  regenerateText: { type: String, default: 'Обновить ответ' },
  upgrade: Boolean,
  upgradeText: { type: String, default: 'Повысить тариф' },
});

let regenerateTimeoutId = null;
const regenerateTimeout = ref(-1);

watch(regenerateTimeout, (newValue, oldValue) => {
  clearTimeout(regenerateTimeoutId);
  if (newValue > 0) {
    regenerateTimeoutId = setTimeout(() => {
      regenerateTimeout.value -= 1;
    }, 1000);
  } else if (newValue === 0 && oldValue > 0) {
    emit('regenerate');
  }
});

const setRegenerateTimeout = (timeout) => {
  if (timeout < 0) {
    clearTimeout(regenerateTimeoutId);
  }
  regenerateTimeout.value = timeout;
};

defineExpose({
  setRegenerateTimeout,
});
</script>

<style lang="scss">
.chat-links{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1em;
  flex-direction: row;
  align-items: center;
  .button{
    padding: .25em 1em;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    background: #fff;
    border-color: #e8e8e8;
  }
}
</style>

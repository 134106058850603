<template>
  <div class="imaging-image">
    <div v-if="removeHandler?.pending" class="imaging-image__message">
      <Balloon theme="error">
        <div class="flex gap-sm">
          <div class="link link_full-gray flex gap-sm ai-center" @click="removeHandler?.call()">
            <ConfirmIcon />
            <span>Удалить</span>
          </div>
          <div class="link link_full-gray flex gap-sm ai-center" @click="removeHandler?.cancel()">
            <CancelIcon />
            <span>Отменить ({{removeHandler.secondsLeft}} сек.)</span>
          </div>
        </div>
      </Balloon>
    </div>
    <template v-else>
      <!--  User  -->
      <div class="imaging-image__message imaging-image__message_user">
        <Avatar icon="user" />
        <Balloon theme="user" corner="4">{{ prompt.userPrompt }}</Balloon>
      </div>
      <!--  Assistant  -->
      <div v-for="image in prompt.images" :key="image.id" class="imaging-image__message imaging-image__message_assistant">
        <Avatar :icon="image.provider" />
        <Balloon :theme="image.error ? 'error' : 'assistant'" corner="4">
          <Loader v-if="image.pending" />
          <template v-else>
            <template v-if="image.error">
              {{ image.error }}
            </template>
            <div v-else class="imaging-image__balloon_content">
              <div class="imaging-image__image-wrapper">
                <Image
                  class="imaging-image__image"
                  :src="appHost + image.imageUrl"
                  :style="{
                    aspectRatio: `${image.imageWidth} / ${image.imageHeight}`,
                    width: `${image.imageWidth}px`,
                }"
                  @click="fullScreenImage = image" />
              </div>
              <div v-if="!hideMenu && image.actions" class="imaging-image__image-actions">
                <div v-for="a in groupActions(image.actions)" :key="a.key"
                     class="image-actions">
                  <div class="image-actions__header">{{a.text}}</div>
                  <div class="image-actions__buttons">
                    <Button
                      v-for="c in a.buttons"
                      :key="c.value"
                      :label="c.text"
                      @click="action(c, image)"
                      severity=""/>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div class="imaging-image__info">
            <div>
              <Icon :icon="image.provider" />
              <span>{{ image.model }}</span>
            </div>
            <div v-if="image.useUnits">
              <ArrowDownCircleIcon />
              {{image.useUnits}}
            </div>
          </div>
        </Balloon>
      </div>
      <div class="imaging-image__menu-bar">
        <Button v-if="!hideMenu"
                icon="share"
                severity=""
                @click="$refs.imagingShareModal.show(prompt)"/>
        <Button v-if="!hideMenu"
                icon="popupMenu"
                severity=""
                @click="$refs.popupMenuElement.toggle($event)"/>
      </div>
    </template>
    <teleport to="body">
      <div class="image-image-fullscreen" v-if="fullScreenImage" @click="fullScreenImage = null">
        <img :src="appHost + fullScreenImage.imageUrl" alt=""/>
      </div>
    </teleport>
    <PopupMenu ref="popupMenuElement" position="bottom right" :vertical-margin="5">
      <MenuItem
        label="Ещё вариант"
        icon="refresh"
        @click="$emit('redraw')"/>
      <MenuItem
        label="Поделиться"
        icon="share"
        @click="$refs.imagingShareModal.show(prompt)"/>
      <MenuItem
        label="Удалить"
        icon="remove"
        @click="removeHandler = deferredAction(5, () => $emit('remove'))" />
    </PopupMenu>
    <ImagingInpaintModal ref="imagingImageModal" @send="inpaintAction" />
    <ImagingShare ref="imagingShareModal" />
  </div>
</template>

<script setup>
import { defineProps, ref } from 'vue';
import Loader from '@/components/Loader.vue';
import Balloon from '@/components/Balloon.vue';
import Avatar from '@/components/Avatar.vue';
import ArrowDownCircleIcon from '@/components/icons/ArrowDownCircleIcon.vue';
import Button from '@/components/Button.vue';
import PopupMenu from '@/components/PopupMenu.vue';
import MenuItem from '@/components/MenuItem.vue';
import ConfirmIcon from '@/components/icons/ConfirmIcon.vue';
import CancelIcon from '@/components/icons/CancelIcon.vue';
import deferredAction from '@/utils/deferred-action';
import Image from '@/components/Image.vue';
import Icon from '@/components/Icon.vue';
import ImagingInpaintModal from '@/components/modals/ImagingInpaint.vue';
import ImagingShare from '@/components/modals/ImagingShare.vue';

const appHost = process.env.VUE_APP_HOST;
defineProps({
  prompt: Object,
  hideMenu: Boolean,
});

const emit = defineEmits(['remove', 'redraw', 'imageAction']);

const removeHandler = ref(null);
const fullScreenImage = ref(null);

const actionTexts = {
  upscale: 'выбрать',
  variation: 'вариации',
  upsample: 'увеличить',
  'upsample:v5_2x': 'x2',
  'upsample:v5_4x': 'x4',
  'upsample:v6_2x_subtle': 'тонко',
  'upsample:v6_2x_creative': 'креативно',
  pan: 'расширить',
  'pan:left': '⬅',
  'pan:right': '➡',
  'pan:up': '⬆',
  'pan:down': '⬇',
  zoom: 'отдалить',
  'zoom:50': '2x',
  'zoom:75': '1.5x',
  'vary_subtle:vary_subtle': 'меньше',
  'vary_strong:vary_strong': 'больше',
  'inpaint:inpaint': 'изменить область...',
};

const groupActions = (actions) => {
  const groups = [];
  const more = [];
  actions.forEach((a) => {
    const buttons = a.choices.map((c) => ({
      type: a.type,
      value: c.value,
      text: actionTexts[`${a.type}:${c.value}`] ?? c.value,
      params: c.params,
    }));
    if (buttons.length > 1) {
      groups.push({ key: a.type, text: actionTexts[a.type] ?? a.type, buttons });
      return;
    }
    if (buttons[0].type.startsWith('vary_')) {
      if (!groups.find(({ key }) => key === 'vary')?.buttons.push(...buttons)) {
        groups.push({ key: 'vary', text: actionTexts.variation, buttons });
      }
      return;
    }
    more.push(...buttons);
  });
  if (more.length > 0) {
    groups.push({ key: 'more', text: 'еще', buttons: more });
  }
  return groups;
};

const imagingImageModal = ref(null);

const inpaintAction = (image, { mask, prompt }) => {
  emit('imageAction', {
    id: image.id, type: 'inpaint', choice: 'inpaint', params: { mask, prompt },
  });
};

const action = ({ type, value: choice, params }, image) => {
  if (type === 'inpaint') {
    imagingImageModal.value?.show(image);
    return;
  }
  emit('imageAction', {
    id: image.id, type, choice, params,
  });
};
</script>

<style lang="scss">
.image-actions{
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: .5em;
  &__header{
    flex-shrink: 0;
    font-size: .9em;
  }
  &__buttons{
    display: flex;
    gap: .25em;
    flex-grow: 1;
    flex-wrap: wrap;
  }
  .button {
    //padding: .5em .75em;
    flex-basis: 0;
    flex-grow: 1;
    justify-content: center;

    padding: .5em .75em;
    //color: #333;
    //background-color: rgba(0, 0, 0, 0.05);
    //border: none;
    &:hover{
      //background-color: rgba(0, 0, 0,.1);
    }
  }
}
.imaging-image{
  padding: 1em;
  background: #f2f2f2;
  border-radius: 10px;
  box-shadow: 0 0 3px rgba(0,0,0,.2);
  position: relative;

  &__message{
    box-sizing: border-box;
    margin-top: 1.5em;
    gap: 1em;
    &:first-child{
      margin-top: 0;
    }
    display: flex;
    align-items: end;
    .loader{
      display: block;
      margin: 0 auto;
      color: #555;
    }
  }
  &__message_user{
    // justify-content: end;
  }
  &__balloon_content{
    width: min-content;
    max-width: 100%;
  }
  &__image-wrapper{
    max-width: 450px;
  }
  &__image{
    max-width: 100%;
    cursor: pointer;
    img{
      border-radius: 3px;
    }
  }
  &__image-actions{
    display: flex;
    flex-direction: column;
    gap: .5em;
    font-size: 14px;
    margin-top: .75em;
  }
  &__info{
    display: flex;
    justify-content: end;
    font-size: .7em;
    margin-top: .8em;
    margin-bottom: -1em;
    gap: 1em;
    > div{
      display: flex;
      gap: .5em;
      align-items: center;
    }
  }
  &__menu-bar{
    position: absolute;
    right: .25em;
    top: .25em;
    display: flex;
    gap: .5em;
    .button{
      padding: .25em;
      color: #555;
      background-color: rgba(255,255,255,.7);
      border: solid 1px rgba(255,255,255,.8);
      &:hover{
        background-color: #fff;
      }
    }
  }

  .balloon{
    white-space: pre-line;
  }
  .balloon_assistant{
    background: #fafafa;
  }
}

@media all and (min-width: 480px) {
  .image-actions{
    flex-direction: row; // !!
    gap: 1em; // !!
    align-items: center; // !!!
    &__header{
      width: 70px; // !!
      font-size: 1em; // !!
    }
  }
  .imaging-image__image-actions{
    width: fit-content;
    min-width: 268px;
  }
}
</style>

<template>
  <div class="nav">
    <div class="nav__header">
      <router-link to="/" custom v-slot="{ navigate }">
        <NavButton @click="navigate" :with-border="false" icon="plus" label="Новый чат" />
      </router-link>
      <div class="nav__logo" v-if="!closeButton">
        <GoGptLogo />
      </div>
      <NavButton :with-border="false" v-if="closeButton" @click="$emit('close')" icon="cancel" />
    </div>
    <NavSeparator />
    <div class="nav__search">
      <SearchIcon />
      <input type="text" v-model.trim="filterText" placeholder="поиск">
      <CancelIcon v-if="filterText" class="link" @click="filterText = ''"/>
    </div>
    <ScrollPanel class="nav__main">
      <template v-for="group in contexts" :key="group.date">
        <NavLabel>{{ group.date }}</NavLabel>
        <router-link v-for="context in group.items" :key="context.id" :to="`/${(isChatContext(context) ? 'chat' : 'imaging')}/${context.id}`" custom
                     v-slot="{ navigate, isExactActive }">
          <NavButton @click="navigate" :is-active="isExactActive" :icon="isChatContext(context) ? 'chat' : 'image'">
            <NavContext
              :title="context.title"
              :editable="isExactActive"
              @remove="removeContext(context)"
              @edit="updateContext(context, $event)"/>
          </NavButton>
        </router-link>
      </template>
    </ScrollPanel>
    <div class="nav__footer">
      <NavSeparator />
      <PopupMenu class="nav-usermenu" ref="userPopupMenu" position="top left">
        <router-link to="/settings" custom v-slot="{ navigate }">
          <MenuItem icon="user" label="Управление аккаунтом" @click="navigate" />
        </router-link>
        <router-link to="/cp" custom v-slot="{ navigate }" v-if="isAdmin">
          <MenuItem icon="control-panel" label="Панель управления" @click="navigate" />
        </router-link>
        <Separator />
        <router-link to="/partner" custom v-slot="{ navigate }">
          <MenuItem icon="handshake" label="Партнерская программа" @click="navigate" />
        </router-link>
        <router-link to="/openapi" custom v-slot="{ navigate }">
          <MenuItem icon="api" label="Интеграция / API" @click="navigate" />
        </router-link>
        <Separator />
        <router-link to="/kb" custom v-slot="{ navigate }">
          <MenuItem icon="help" label="База знаний" @click="navigate" />
        </router-link>
        <MenuItem icon="support" label="Поддержка" href="https://t.me/GoGptSupportBot" target="_blank" />
        <MenuItem icon="feed" label="Новости и лайфхаки" href="https://t.me/GoGptRu" target="_blank" />
        <MenuItem icon="community" label="Нейросообщество" href="https://t.me/gogptclub" target="_blank" />
        <Separator />
        <MenuItem icon="telegram" label="Telegram чат-бот" @click="telegramGo('GoGptRuBot')" />
        <Separator />
        <router-link to="/payment" custom v-slot="{ navigate }">
          <MenuItem icon="crown" label="Тариф и оплата" @click="navigate">
            <template #icon>
              <Icon icon="crown" class="error-text" />
            </template>
          </MenuItem>
        </router-link>
      </PopupMenu>
      <NavButton icon="menu2" @click="$refs.userPopupMenu.toggle($event)">
        <div class="flex jc-spbt ai-center">
          <span>Меню</span>
          <strong class="error-text">{{unitBalance}}</strong>
        </div>
      </NavButton>
    </div>
  </div>
</template>

<script setup>
import {
  computed, ref, shallowRef, watch,
} from 'vue';
import { useRouter } from 'vue-router';
import moment from 'moment';
import api from '@/utils/api';
// import { auth as tgAuth } from '@/utils/telegram';
import useTelegramGo from '@/composables/telegram-go';
import useAuthStore from '@/store/auth';
import useAccountStore from '@/store/account';
import useChatStore from '@/store/chat';
import useImagingStore from '@/store/imaging';
import NavButton from '@/components/NavButton.vue';
import NavSeparator from '@/components/NavSeparator.vue';
import NavContext from '@/components/NavContext.vue';
import ScrollPanel from '@/components/ScrollPanel.vue';
import GoGptLogo from '@/components/GoGptLogo.vue';
import PopupMenu from '@/components/PopupMenu.vue';
import MenuItem from '@/components/MenuItem.vue';
import Separator from '@/components/Separator.vue';
import Icon from '@/components/Icon.vue';
import CancelIcon from '@/components/icons/CancelIcon.vue';
import SearchIcon from '@/components/icons/SearchIcon.vue';
import NavLabel from '@/components/NavLabel.vue';

defineProps({
  closeButton: Boolean,
});
defineEmits(['close']);

const router = useRouter();

const authStore = useAuthStore();
const chatStore = useChatStore();
const imagingStore = useImagingStore();
const accountStore = useAccountStore();
const telegramGo = useTelegramGo();

const isAdmin = computed(() => authStore.user?.isAdmin);
const filterText = ref('');
const filterIds = shallowRef(null);

const calendar = (timestamp) => moment.unix(timestamp).calendar(null, {
  sameDay: '[Сегодня]',
  lastDay: '[Вчера]',
  nextDay: 'DD.MM.YYYY',
  nextWeek: 'DD.MM.YYYY',
  lastWeek: 'DD.MM.YYYY',
  sameElse: 'DD.MM.YYYY',
});

const isChatContext = (context) => 'ecoMode' in context;

const filterByContext = (context) => (isChatContext(context) ? filterIds.value?.chat : filterIds.value?.imaging);

const contexts = computed(() => {
  let all = chatStore.contexts.concat(imagingStore.contexts);
  if (filterIds.value) {
    all = all.filter((c) => filterByContext(c)?.includes(c.id));
  }
  const groups = [];
  let date;
  let items;
  all.sort((a, b) => b.updatedAt - a.updatedAt).forEach((c) => {
    const d = calendar(c.updatedAt);
    if (date !== d) {
      date = d;
      items = [];
      groups.push({ date, items });
    }
    items.push(c);
  });
  return groups;
});

let updateFilterTid = null;
watch(filterText, (text) => {
  clearTimeout(updateFilterTid);
  const tid = setTimeout(async () => {
    if (!text) {
      filterIds.value = null;
      return;
    }
    const ids = await api.get('conversation/find-id', { params: { text } });
    if (tid === updateFilterTid) {
      filterIds.value = ids;
    }
  }, 200);
  updateFilterTid = tid;
}, { immediate: true });

const unitBalance = computed(() => {
  const units = accountStore.unitsLimit;
  if (units > 1000000) {
    return `${(units / 1000000).toFixed(1)}kk+`;
  }
  if (units > 1000) {
    return `${(units / 1000).toFixed(1)}k+`;
  }
  return units;
});

const removeContext = (context) => {
  if (isChatContext(context)) {
    chatStore.removeContext(context.id);
  } else {
    imagingStore.removeContext(context.id);
  }
  router.push('/');
};

const updateContext = (context, title) => {
  if (title.trim() === '') {
    return;
  }
  if (isChatContext(context)) {
    chatStore.saveContext(context.id, { title });
  } else {
    imagingStore.saveContext(context.id, { title });
  }
};

// const goToTelegramBot = () => {
//   const open = () => window.open('https://t.me/GoGptRuBot', '_blank');
//
//   if (authStore.user.telegramUserId) {
//     open();
//     return;
//   }
//   tgAuth(async (authData) => {
//     try {
//       await authStore.connectTelegram('', authData);
//       open();
//     } catch (ex) {
//       //
//     }
//   });
// };
</script>

<style lang="scss">
.nav-usermenu{
  font-size: 14px;
  background: #fff;
  color: #282828;
  .menu-item{
    padding: .7em .5em;
    gap: 1em;
  }
  .menu-item:hover{
    background-color: #e8e8e8;
  }
}
.nav {
  background: #202023;
  color: #fff;
  height: 100%;
  box-sizing: border-box;
  padding: .5em;
  display: flex;
  flex-direction: column;

  &__search{
    display: flex;
    align-items: center;
    background: #2a2a2a;
    color: #fff;
    border-radius: 5px;
    padding: .5em 1em;
    font-size: 14px;
    svg{
      font-size: 16px;
      color: #fafafa;
      &.link{
        color: #19c37d;
        &:hover{
          color: #fff;
        }
      }
    }
    input{
      width: 0;
      flex-grow: 1;
      outline: none;
      background-color: inherit;
      color: inherit;
      border: none;
      padding: 0 0 0 1em;
    }
  }

  &__header {
    display: flex;
    gap: .5em;
    align-items: center;
    justify-content: space-between;
  }

  &__logo{
    padding: 0 1em;
  }

  &__main, &__footer {
    display: flex;
    flex-direction: column;
    row-gap: .2em;
  }

  &__main{
    flex-grow: 1;
    margin-top: .5em;
  }

  &__footer .nav-separator:first-child{
    padding-bottom: 0;
  }

  //&__expand{
  //  text-align: center;
  //  color: #888;
  //  font-size: 10px;
  //  padding: .2em;
  //  //margin-top: -.7em;
  //  //font-variant: small-caps;
  //  text-transform: uppercase;
  //  &:hover{
  //    color: #fff;
  //  }
  //}

  .separator{
    color: #999;
  }
}

.nav-button__icon svg{
  width: 1.15em;
  height: 1.15em;
}
</style>

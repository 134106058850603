<template>
  <div class="chat-form">
    <div v-if="message">
      <Attachment
        class="chat-form__message-attachment"
        :content="message.message"
        icon="edit"
        @close="$emit('update:message', null)" />
    </div>

    <ChatFormAttachments
      ref="attachmentsElement"
      v-show="attachments.items.value.length"
      :attachments="attachments" />

    <div v-if="template">
      <Attachment
        icon="template"
        :content="template.title"
        @close="$emit('update:template', null)" />
      <div class="chat-form__extra" v-if="extraFields.length">
        <input
          type="text"
          class="chat-form__input"
          v-for="(f, i) in extraFields" :key="i"
          :placeholder="f.hint || 'Дополнительно'"
          v-model.trim="f.value"
          @input="updateMessageCost()">
      </div>
    </div>

    <div class="new-chat-here" v-if="!context && !template && !attachments.items.value.length">
      <ArrowLeftDownIcon />
      <div>Воспользуйтесь готовым шаблоном запроса или создайте свой!</div>
    </div>

    <Prompt
      ref="promptEl"
      :placeholder="mainFiled.hint || 'Сообщение'"
      :prompt-disabled="!mainFiled.enabled"
      prompt-disabled-text="Ввод не требуется"
      :busy="sendProcess"
      v-model:prompt="mainFiled.value"
      :can-send="canSend"
      @send="send()">
      <template #buttons>
        <PromptButton icon="template" @click="$emit('template')"/>
        <PromptButton icon="clip" @click="$refs.attachmentPopupMenu.toggle($event)"/>
        <PopupMenu ref="attachmentPopupMenu" position="top left" vertical-margin="10">
          <MenuItem icon="globe" label="Страница в интернете" @click="canAttach() && $refs.attachmentsElement?.attachWebpage()" />
          <MenuItem icon="youtube" label="Видео YouTube" @click="canAttach() && $refs.attachmentsElement?.attachYoutube()" />
          <MenuItem icon="lens" label="Изображение" @click="canAttach() && $refs.attachmentsElement?.attachImage()" />
          <MenuItem icon="clip" label="Файл" @click="canAttach() && $refs.attachmentsElement?.attachFile()" />
        </PopupMenu>
      </template>
    </Prompt>

    <div class="chat-form__options">
      <div class="chat-form__option">
        <div>Эко<span class="shrink-text">номный режим</span></div>
        <Toggle :checked="ecoMode" @update:checked="updateEcoMode($event)"/>
      </div>
      <div class="chat-form__option">
        <div class="shrink-text">Модель</div>
        <ModelSelect ref="modelSelectElement" :model="model" @update:model="updateModel($event)"/>
      </div>
      <div class="chat-form__option">
        Стоимость<span class="shrink-text"> запроса</span>&nbsp;
        <Loader v-if="contextCost === null || messageCost === null"/>
        <span v-else :class="{ 'error-text': contextCost + messageCost > largeCost}">~<strong>{{ numeralFormat(contextCost + messageCost) }}</strong></span>
      </div>
    </div>

    <WarningLargeRequestModal ref="warningLargeRequestModal" />
    <WarningModelContextLimit ref="warningModelContextLimit" />
    <WarningChatAttachments ref="warningChatAttachments" />
  </div>
</template>

<script setup>
import {
  computed, defineProps, nextTick, onMounted, reactive, ref, watch,
} from 'vue';
import { useRoute, onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router';
import api from '@/utils/api';
import useAppStore from '@/store/app';
import useAccountStore from '@/store/account';
import useChatStore from '@/store/chat';
import {
  extractFields as extractFieldsFromTemplate,
  toMessage as templateToMessage,
} from '@/utils/template';
import Loader from '@/components/Loader.vue';
import Toggle from '@/components/Toggle.vue';
import ModelSelect from '@/components/ModelSelect.vue';
import Attachment from '@/components/Attachment.vue';
import PopupMenu from '@/components/PopupMenu.vue';
import MenuItem from '@/components/MenuItem.vue';
import Prompt from '@/components/Prompt.vue';
import PromptButton from '@/components/PromptButton.vue';
import ChatFormAttachments from '@/components/ChatFormAttachments.vue';
import useChatAttachments from '@/services/chat-attachments';
import WarningLargeRequestModal from '@/components/modals/WarningLargeRequest.vue';
import WarningModelContextLimit from '@/components/modals/WarningModelContextLimit.vue';
import WarningChatAttachments from '@/components/modals/WarningChatAttachments.vue';
import ArrowLeftDownIcon from '@/components/icons/ArrowLeftDownIcon.vue';

const props = defineProps({
  template: Object,
  message: Object,
  canSendMessage: { type: Boolean, default: true },
});
const emit = defineEmits([
  'send-before',
  'send-after',
  'new-message',
  'error',
  'template',
  'update:template',
  'update:message',
]);
const route = useRoute();

const largeCost = 100_000;

const appStore = useAppStore();
const accountStore = useAccountStore();
const chatStore = useChatStore();

const promptEl = ref(null);

const context = computed(() => chatStore.context);

const mainFiled = reactive({
  enabled: true,
  hint: '',
  value: localStorage.getItem(`prompt_chat${route.params.contextId}`) ?? '',
});
const extraFields = ref([]);
const saveDraft = () => {
  const prompt = mainFiled.value.trim();
  const key = `prompt_chat${route.params.contextId}`;
  if (prompt && !props.message) {
    localStorage.setItem(key, prompt);
  } else {
    localStorage.removeItem(key);
  }
};

onBeforeRouteUpdate(saveDraft);
onBeforeRouteLeave(saveDraft);

const attachments = useChatAttachments();
const warningChatAttachments = ref(null);

const canAttach = () => {
  const { account } = accountStore;
  if (account?.supportsVision) {
    return true;
  }
  warningChatAttachments.value?.show();
  return false;
};

const updateContext = async (fields) => {
  if (context.value) {
    await chatStore.saveContext(context.value.id, fields);
  }
};

// Стоимость контекста
const contextCost = ref(0);
let updateContextCostId = 0;
const updateContextCost = async () => {
  updateContextCostId += 1;
  const id = updateContextCostId;
  if (!context.value || props.template?.useContext === false || props.message) {
    contextCost.value = 0;
    return;
  }
  contextCost.value = null;
  const cost = await api.get('chat/context/cost', { params: { id: context.value.id } });
  if (id === updateContextCostId) {
    contextCost.value = cost;
  }
};

// Экономный режим
const ecoMode = ref(null);
const updateEcoMode = async (value) => {
  await updateContext({ ecoMode: value });
  ecoMode.value = value;
};

const model = ref(null);
const updateModel = async (value) => {
  await updateContext({ modelId: value });
  model.value = value;
};

// Новое сообщение
const getMessage = () => (props.template
  ? templateToMessage(props.template.template, mainFiled.value, extraFields.value.map((f) => f.value))
  : mainFiled.value).trim();

// Стоимость сообщения
const messageCost = ref(0);
let updateMessageCostTid = null;
const updateMessageCost = () => {
  clearTimeout(updateMessageCostTid);

  const message = getMessage();
  if (message === '' || props.message) {
    messageCost.value = 0;
    return;
  }
  if (attachments.items.value.some((i) => i.busy)) {
    return;
  }
  messageCost.value = null;
  updateMessageCostTid = setTimeout(async () => {
    messageCost.value = await api.post('chat/message/cost', {
      message,
      modelId: model.value,
      attachments: attachments.items.value.map(({ id }) => id),
    });
  }, 1000);
};

const reset = () => {
  mainFiled.value = '';
  extraFields.value.forEach((f) => {
    f.value = '';
  });
  attachments.clear();
  emit('update:template', null);
  emit('update:message', null);
};

const sendProcess = ref(false);
const canSend = computed(() => {
  if (mainFiled.enabled && !mainFiled.value) {
    return false;
  }
  if (contextCost.value === null || messageCost.value === null) {
    return false;
  }
  return !extraFields.value.some((f) => f.value === '') && !attachments.items.value.some((i) => i.busy);
});

const warningLargeRequestModal = ref(null);
const warningModelContextLimit = ref(null);

const send = async () => {
  const message = getMessage();

  if (!message || sendProcess.value || !props.canSendMessage) {
    return;
  }

  const messageId = props.message?.id;
  if (!messageId && accountStore.account?.requestsLimit === 0) {
    emit('error', 'Вы исчерпали лимит запросов на сегодня.');
    return;
  }

  const cost = contextCost.value + messageCost.value;

  // Проверить запрос на вхождение в контекстное окно
  const currentModel = chatStore.models.find((m) => m.id === model.value);
  const maxModelCost = currentModel.cost * currentModel.contextLimit;
  if (cost > maxModelCost) {
    warningModelContextLimit.value?.show({ model: currentModel, cost });
    return;
  }
  // Проверить запрос на перерасход токенов
  let ignoreLargeRequest;
  if (!context.value?.ignoreLargeRequest && cost > largeCost) {
    try {
      const result = await warningLargeRequestModal.value?.show({ context: context.value, cost });
      ignoreLargeRequest = result.ignoreLargeRequest;
    } catch {
      return;
    }
  }

  emit('send-before');
  sendProcess.value = true;

  const contextId = context.value?.id ?? await chatStore.saveContext(null, {
    title: message.substring(0, 50),
    ecoMode: ecoMode.value,
    modelId: model.value,
    ignoreLargeRequest,
  });
  try {
    const { ignore, useContext } = props.template ?? {};
    const messageData = {
      message, ignore, useContext, attachments: attachments.items.value.map(({ id }) => id),
    };
    if (messageId) {
      await chatStore.saveMessage(messageId, messageData);
    } else {
      await chatStore.sendMessage(contextId, null, messageData);
    }
  } catch (e) {
    emit('error', e.message);
    return;
  } finally {
    sendProcess.value = false;
    emit('send-after');
  }

  reset();
  if (!messageId) {
    emit('new-message', { contextId });
  }
};

watch(() => [context.value?.ecoMode, context.value?.modelId], ([newEcoMode, newModel]) => {
  ecoMode.value = newEcoMode !== false;
  model.value = newModel ?? chatStore.models.find((m) => m.isDefault)?.id ?? chatStore.models?.[0].id;
}, { immediate: true });

watch([context, ecoMode, model, () => props.template?.useContext, () => props.message], () => {
  updateContextCost();
  updateMessageCost();
}, { immediate: true });

watch(() => mainFiled.value, () => {
  updateMessageCost();
});

const modelSelectElement = ref(null);
watch(attachments.items, (value) => {
  if (value.some((i) => i.type === 'image')) {
    modelSelectElement.value?.selectVisionModel();
  }
  updateMessageCost();
}, { deep: true });

watch(() => props.template?.template, (t) => {
  const { main = '', extra = [] } = t ? extractFieldsFromTemplate(t) : {};
  mainFiled.enabled = main !== false;
  mainFiled.hint = main;
  extraFields.value = extra.map((f) => ({ hint: f, value: '' }));
  updateMessageCost();
});

watch(() => props.message, (m, old) => {
  attachments.clear();
  if (!m && !old) {
    return;
  }
  mainFiled.value = m?.message || '';
  m?.attachments.forEach((a) => {
    attachments.add({ ...a });
  });
}, { immediate: true });

if (!appStore.isMobile) {
  onMounted(async () => {
    await nextTick();
    promptEl.value?.focus();
  });
}

defineExpose({
  reset,
  updateContextCost,
});
</script>

<style lang="scss">
.new-chat-here{
  display: flex;
  align-items: end;
  gap: .2em;
  margin-left: .8em;
  svg{
    font-size: 1.925em;
    flex-shrink: 0;
  }
  div{
    font-size: 1.2em;
    padding-bottom: .8em;
  }
}
.chat-form {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__extra{
    display: flex;
    gap: .5em;
    flex-wrap: wrap;
  }

  &__extra {
    row-gap: 1em;
    margin-top: .5em;
  }

  &__input {
    outline: none;
    border: solid 1px #dadada;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .1);
    width: 1px;
    flex-grow: 1;
    line-height: 1.2;
    padding: .8em;
    font-size: .9em;
  }

  &__option {
    display: flex;
    align-items: center;
    gap: .5em;
  }

  &__options {
    font-size: 12px;
    display: flex;
    gap: 1em;
    padding: 0 10px 10px;
  }

  &__options &__option:last-child{
    margin-left: auto;
  }

  &__message-attachment .attachment__content{
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media (min-width: 540px) {
  .chat-form__options .shrink-text {
    display: inline;
  }
}
</style>

<template>
  <div class="nav-context">
    <span class="nav-context__label" v-if="!editable || mode !== 'edit'">{{ title }}</span>
    <input type="text" class="nav-context__input" v-model="newTitle" v-if="editable && mode === 'edit'" @keydown.enter="confirm()">
    <div class="nav-context__controls" v-if="editable">
      <span class="nav-context__button" v-if="mode !== ''" @click.stop="confirm()"><ConfirmIcon/></span>
      <span class="nav-context__button" v-if="mode !== ''" @click.stop="mode=''"><CancelIcon/></span>
      <span class="nav-context__button" v-if="mode === ''" @click.stop="edit()"><EditIcon/></span>
      <span class="nav-context__button" v-if="mode === ''" @click.stop="mode='remove'"><RemoveIcon/></span>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import EditIcon from '@/components/icons/EditIcon.vue';
import RemoveIcon from '@/components/icons/RemoveIcon.vue';
import ConfirmIcon from '@/components/icons/ConfirmIcon.vue';
import CancelIcon from '@/components/icons/CancelIcon.vue';

const props = defineProps({
  title: String,
  editable: Boolean,
});

const newTitle = ref('');

const emit = defineEmits(['edit', 'remove']);

const mode = ref('');

watch(() => props.editable, () => {
  mode.value = '';
});

const edit = () => {
  newTitle.value = props.title;
  mode.value = 'edit';
};

const confirm = () => {
  if (mode.value === 'edit') {
    if (newTitle.value.trim() === '') {
      return;
    }
    emit('edit', newTitle.value);
  } else {
    emit(mode.value);
  }
  mode.value = '';
};
</script>

<style lang="scss">
.nav-context{
  display: flex;
  column-gap: 1em;

  &__label{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    width: 0;
  }

  &__input{
    display: block;
    flex-grow: 1;
    width: 0;
    border: none;
    outline: none;
    margin: -.2em 0;
    padding: .2em;
    border-radius: .2em;
    background: #c6c6c9;
  }

  &__controls{
    display: flex;
    column-gap: .7em;
    margin-left: auto;
  }

  &__button {
    //cursor: pointer;
    //color: #fff;
    transition: color .3s ease;
    &:hover{
      color: #3a8ccd;
    }
  }
}
</style>

<template>
<div class="accordion">
  <div v-for="(p, i) in items" :key="i" class="accordion__item" :class="{'accordion__item_active': isActive(i)}">
    <div class="accordion__header" @click="toggle(i)">
      <div class="accordion__title">
        <component :is="p.children.header" v-if="p.children.header"/>
        <span v-else>{{p.props?.header}}</span>
      </div>
      <div class="accordion__arrow">
        <ChevronDownIcon v-if="isActive(i)" />
        <ChevronRightIcon v-else />
      </div>
    </div>
    <Transition name="transition-v-slide">
      <div class="accordion__toggle" v-show="isActive(i)">
        <div class="accordion__content"><component :is="p" /></div>
      </div>
    </Transition>
  </div>
</div>
</template>

<script setup>
import { computed, ref, useSlots } from 'vue';
import ChevronDownIcon from '@/components/icons/ChevronDownIcon.vue';
import ChevronRightIcon from '@/components/icons/ChevronRightIcon.vue';

const props = defineProps({
  selectedIndex: { type: Number, default: -1 },
});
const slots = useSlots();

const items = computed(() => slots.default().reduce((arr, c) => {
  if (c.type.name === 'AccordionItem') {
    arr.push(c);
  } else if (c.children && c.children instanceof Array) {
    c.children.forEach((nc) => {
      if (nc.type.name === 'AccordionItem') {
        arr.push(nc);
      }
    });
  }
  return arr;
}, []));

const currentItem = ref(props.selectedIndex);

const isActive = (i) => currentItem.value === i;

const toggle = (i) => {
  currentItem.value = isActive(i) ? -1 : i;
};
</script>

<style lang="scss">
.accordion{
  display: flex;
  flex-direction: column;
  gap: 1em;

  &__item{
    border: solid 1px #e0e0e0;
    box-shadow: 0 0 3px rgba(0,0,0,.1);
    border-radius: 5px;
  }

  &__header{
    padding: 1em;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #777;
    justify-content: space-between;
    transition: color .3s ease;
  }

  &__arrow{
    flex-shrink: 0;
    margin-right: .5em;
  }

  &__toggle{
    padding: 0 1em 1em;
  }

  &__content{
    margin-top: 1em;
    font-size: .9em;
  }

  &__item:hover &__header, &__item_active &__header{
    color: #000;
  }
}
</style>

<template>
  <component :is="$attrs.href ? 'a' : 'button'" class="button" :class="classes" @click="click">
    <Loader v-if="isBusy"/>
    <slot name="icon" v-else-if="icon || $slots.icon">
      <Icon :icon="icon" />
    </slot>
    <span v-if="label || $slots.default"><slot>{{ label }}</slot></span>
  </component>
</template>

<script setup>
import {
  defineProps, computed,
} from 'vue';
import Loader from '@/components/Loader.vue';
import Icon from '@/components/Icon.vue';

const props = defineProps({
  icon: String,
  label: String,
  isBusy: Boolean,
  severity: { type: String, default: 'primary' },
});

const classes = computed(() => [
  props.severity ? `button_${props.severity}` : '',
]);

const click = (ev) => {
  if (props.isBusy) {
    ev.preventDefault();
  }
};
</script>

<style lang="scss">
.button{
  padding: .7em 1em;
  border: solid #d8d8d8 1px;
  color: #222;
  background: #e8e8e8;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color .3s ease, color .3s ease, transform .15s ease;
  display: inline-flex;
  align-items: center;
  column-gap: .5em;
  text-decoration: none;
  &:hover{
    background: #f0f0f0;
  }
}

.button_primary{
  background: #282828;
  color: #fff;
  border: none;
  &:hover{
    background: #121314;
  }
}

.button_error{
  background: #c33219;
  color: #fff;
  border: none;
  transition: background-color .3s ease;
  &:hover{
    background: #d04025;
  }
}

.button[disabled], .button:disabled{
  background: #e8e8e8;
  color: #666;
  cursor: default;
}
</style>

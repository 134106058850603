<template>
  <Modal ref="modalElement" class="modal-subscribe-tg-channel">
    <template #header>Выбор тарифа</template>
    <Notice severity="info" class="mb-md">
      Для доступа к нейросетям выберите тариф:
    </Notice>
    <PlanList>
      <div class="plan-card">
        <div class="plan-card__title">Бесплатный</div>
        <Price class="plan-card__price" :price="0" />
        <div>в месяц*</div>
        <Separator />
        <div class="plan-card__futures">
          <div><Price :price="60000" sign="go"/>**</div>
          <div>10&thinsp;запросов в день</div>
          <div>Доступ к GPT-4 Omni Mini</div>
          <div>Доступ к Claude Haiku</div>
          <div>Доступ к Midjourney 5.2</div>
          <Notice severity="error">
            Необходима активная подписка на Telegram-канал @GoGptRu
          </Notice>
        </div>
        <Button icon="telegram"
                style="margin-top: auto"
                href="https://t.me/GoGptRu"
                target="_blank"
                @click="subscribe()">Подписаться</Button>
      </div>
      <div class="plan-card">
        <div class="plan-card__title">Платный</div>
        <div>от <Price class="plan-card__price" :price="699" /></div>
        <div>в месяц*</div>
        <Separator />
        <div class="plan-card__futures">
          <div>до <Price :price="6_000_000" sign="go"/>**</div>
          <div>Доступ к GPT-4 Omni Mini, GPT-4 Omni, GPT-4 Omni 1</div>
          <div>Доступ к Claude Haiku, Sonnet, Opus</div>
          <div>Доступ к Midjourney 5.2, Midjourney 6.1 и Dall-E 3</div>
          <div>Анализ изображений</div>
          <div>Анализ YouTube-видео</div>
          <div>Обработка ссылок</div>
          <div>Обработка текстовых файлов (PDF, Word и др.)</div>
        </div>
        <RouterLink to="/payment" custom v-slot="{ navigate }">
          <Button style="margin-top: auto" @click="navigate(); $refs.modalElement.hide()">Выбрать тариф</Button>
        </RouterLink>
      </div>
    </PlanList>
    <Notice class="mt-md">
      <div>* Месяц = 30 дней.</div>
      <div>** GoCoin (go) - это валюта, которая расходуется для получения ответов от нейросети.</div>
    </Notice>
    <template #footer="{ hide }">
      <Button type="button" icon="cancel" label="Закрыть" severity="" @click="hide()" />
    </template>
  </Modal>
</template>

<script setup>
import { defineExpose, ref } from 'vue';
import delay from '@/utils/delay';
import api from '@/utils/api';
import Modal from '@/components/Modal.vue';
import Button from '@/components/Button.vue';
import PlanList from '@/components/PlanList.vue';
import Separator from '@/components/Separator.vue';
import Price from '@/components/Price.vue';
import Notice from '@/components/Notice.vue';

const modalElement = ref(null);

defineProps({
  model: Object,
});

defineExpose({
  show: () => modalElement.value?.show(),
});

const subscribe = async () => {
  await api.post('account/subscribe');
  await delay(2000);
  modalElement.value?.hide();
};
</script>

<style lang="scss">
.modal-subscribe-tg-channel .modal__window{
  width: 100%;
  max-width: 450px;
}
.modal-subscribe-tg-channel .modal__content{
  font-size: 14px;
}
.modal-subscribe-tg-channel .plan-list{
  flex-wrap: nowrap;
}
.modal-subscribe-tg-channel .plan-card{
  flex-grow: 1;
  font-size: 1em;
}
@media all and (min-width: 540px) {
  .modal-subscribe-tg-channel .modal__content{
    font-size: 16px;
  }
}
</style>

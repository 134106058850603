<template>
  <div class="choise">
    <div class="choise__trigger" @click="$refs.menuElement.toggle($event)">
      <slot name="value" v-if="modelValue">
        <div class="choise__value">
          <component v-if="$slots.itemvalue" :is="$slots.itemvalue" :item="modelValue" />
          <template v-else>{{ itemLabel?.(modelValue) ?? modelValue }}</template>
        </div>
      </slot>
      <slot name="empty" v-else>
        <div class="choise__value">{{ emptyText }}</div>
      </slot>
    </div>
    <PopupMenu
      ref="menuElement"
      class="choise-popup-menu"
      position="bottom right"
      v-bind="pt?.menu">
      <MenuItem v-for="item in items"
                :key="itemKey(item)"
                :icon="itemIcon?.(item)"
                :label="itemLabel?.(item)"
                @click="choise(item)">
        <template #icon v-if="$slots.itemicon">
          <slot name="itemicon" :item="item"></slot>
        </template>
        <template #default v-if="$slots.itemvalue">
          <slot name="itemvalue" :item="item"></slot>
        </template>
      </MenuItem>
    </PopupMenu>
  </div>
</template>

<script setup>
import {
  defineProps, defineEmits, ref, watch,
} from 'vue';
import PopupMenu from '@/components/PopupMenu.vue';
import MenuItem from '@/components/MenuItem.vue';

const props = defineProps({
  modelValue: undefined,
  items: { type: Array },
  itemKey: { type: Function },
  itemLabel: { type: Function },
  itemIcon: { type: Function },
  emptyText: { type: String, default: 'Выберите' },
  pt: { type: Object, default: () => ({}) },
});

const emit = defineEmits(['update:modelValue']);

const modelValue = ref(null);

watch(() => props.modelValue, (value) => {
  modelValue.value = value;
}, { immediate: true });

const choise = (item) => {
  modelValue.value = item;
  emit('update:modelValue', item);
};
</script>

<style lang="scss">
.choise{
  display: inline-block;
  &__value{
    cursor: pointer;
    //color: #555;
    //transition: color .15s ease;
    text-decoration: underline dotted;
    &:hover{
      //color: #222;
    }
  }
}
</style>

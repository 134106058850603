<template>
  <div ref="container" class="image-mask-editor">
    <img v-if="image" alt="" :src="image.src" :width="canvasWidth" :height="canvasHeight"/>
    <canvas
      ref="canvas"
      :width="canvasWidth"
      :height="canvasHeight"
      @mousedown="onCanvasMouseDown"
      @touchstart="onCanvasTouchStart"
      @contextmenu.prevent/>
    <Button icon="undo"
            @click="shapes.splice(-1)"/>
  </div>
</template>

<script setup>
import {
  computed, defineProps, defineExpose, ref, shallowRef, watch, reactive, nextTick,
} from 'vue';
import { useEventListener } from '@/composables/event-listener';
import Button from '@/components/Button.vue';

const props = defineProps({
  src: String,
});

const container = ref(null);
const canvas = ref(null);
const image = shallowRef(null);
const scale = ref(0);
const shapes = reactive([]);

const canvasWidth = computed(() => (image.value?.width ?? 0) * scale.value);
const canvasHeight = computed(() => (image.value?.height ?? 0) * scale.value);

const createGridPattern = (width, height) => {
  const c = document.createElement('canvas');
  const ctx = c.getContext('2d');
  c.width = width;
  c.height = height;
  const hw = width / 2;
  const hh = height / 2;
  ctx.fillStyle = '#fff';
  ctx.fillRect(0, 0, hw, hh);
  ctx.fillRect(hw, hh, hw, hh);
  ctx.fillStyle = '#000';
  ctx.fillRect(hw, 0, hw, hh);
  ctx.fillRect(0, hh, hw, hh);
  return ctx.createPattern(c, 'repeat');
};

const getMaskImage = (asBlob = false) => {
  const c = document.createElement('canvas');
  const ctx = c.getContext('2d');
  c.width = image.value?.width;
  c.height = image.value?.height;
  ctx.fillStyle = '#000';
  ctx.fillRect(0, 0, c.width, c.height);
  ctx.fillStyle = '#fff';
  shapes.forEach((shape) => {
    ctx.beginPath();
    ctx.moveTo(shape[0].x, shape[0].y);
    shape.forEach(({ x, y }) => ctx.lineTo(x, y));
    ctx.fill();
  });
  if (!asBlob) {
    return c.toDataURL();
  }
  return new Promise((resolve) => {
    c.toBlob((blob) => resolve(blob));
  });
};

const repaint = () => {
  if (!canvas.value) {
    return;
  }
  const s = scale.value;
  const c = canvas.value;
  const ctx = c.getContext('2d');
  ctx.save();
  ctx.setTransform(s, 0, 0, s, 0, 0);
  ctx.globalCompositeOperation = 'source-over';
  ctx.clearRect(0, 0, canvasWidth.value / s, canvasHeight.value / s);
  ctx.fillStyle = '#fff';
  shapes.forEach((shape) => {
    ctx.beginPath();
    ctx.moveTo(shape[0].x, shape[0].y);
    shape.forEach(({ x, y }) => ctx.lineTo(x, y));
    ctx.fill();
  });
  ctx.globalCompositeOperation = 'source-in';
  ctx.globalAlpha = 0.3;
  ctx.fillStyle = createGridPattern(12 / s, 12 / s);
  ctx.fillRect(0, 0, canvasWidth.value / s, canvasHeight.value / s);
  ctx.restore();
};

const invalidateCanvas = () => nextTick(repaint);

const updateScale = async () => {
  if (!container.value || !image.value) {
    scale.value = 0;
    return;
  }
  const { clientWidth, clientHeight } = container.value;
  const { width, height } = image.value;
  scale.value = Math.min(1, clientWidth / width, clientHeight / height);
};

const loadImage = () => {
  const img = new Image();
  shapes.splice(0);
  img.onload = () => {
    image.value = img;
  };
  img.onerror = () => {
    image.value = null;
  };
  img.src = props.src;
};

watch(() => props.src, () => loadImage(), { immediate: true });

const resizeObserver = new ResizeObserver(() => window.requestAnimationFrame(updateScale));
watch(container, (newValue, oldValue) => {
  if (oldValue) resizeObserver.unobserve(oldValue);
  if (newValue) resizeObserver.observe(newValue);
});

watch(image, () => updateScale());

watch([scale, shapes], () => invalidateCanvas(), { deep: true });

const getPoint = ({ clientX, clientY }) => {
  const rect = canvas.value.getBoundingClientRect();
  const x = (clientX - rect.left) / scale.value;
  const y = (clientY - rect.top) / scale.value;
  return { x, y };
};

let drawing = false;
const onCanvasMouseDown = (e) => {
  shapes.push([getPoint(e)]);
  drawing = true;
};
const onCanvasTouchStart = (e) => {
  if (drawing) {
    return;
  }
  [drawing] = e.touches;
  shapes.push([getPoint(drawing)]);
};

useEventListener(window, 'mousemove', (e) => {
  if (!drawing) {
    return;
  }
  shapes[shapes.length - 1].push(getPoint(e));
});

const identifiedTouch = (touchList, identifier) => {
  for (let i = 0; i < touchList.length; i += 1) {
    if (touchList.item(i).identifier === identifier) {
      return touchList.item(i);
    }
  }
  return null;
};

useEventListener(window, 'touchmove', (e) => {
  if (!drawing) {
    return;
  }
  const touch = identifiedTouch(e.changedTouches, drawing.identifier);
  if (touch) shapes[shapes.length - 1].push(getPoint(touch));
});

useEventListener(window, 'mouseup', (e) => {
  if (!drawing) {
    return;
  }
  shapes[shapes.length - 1].push(getPoint(e));
  drawing = false;
});

useEventListener(window, 'touchend', (e) => {
  if (!drawing) {
    return;
  }
  const touch = identifiedTouch(e.changedTouches, drawing.identifier);
  if (!touch) {
    return;
  }
  if (shapes[shapes.length - 1].length > 1) {
    shapes[shapes.length - 1].push(getPoint(touch));
  } else {
    shapes.splice(-1);
  }

  drawing = false;
});

defineExpose({
  getMaskImage,
});
</script>

<style lang="scss">
.image-mask-editor {
  display: grid;
  align-items: center;
  justify-items: center;
  overflow: hidden;
  background: #282828;

  img, canvas, button {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
  }

  canvas {
    touch-action: none;
  }

  button{
    align-self: start;
    justify-self: start;
    justify-content: center;
    margin: .5em;
    width: 2.5em;
    height: 2.5em;
    padding: 0;
    border-radius: 50%;
    background: #444;
    color: #ccc;
    box-shadow: 0 0 5px rgba(0, 0, 0, .15);
    &:hover{
      background: #555;
    }
  }
}
</style>

<template>
  <slot name="header"></slot>
  <slot></slot>
</template>

<script>
export default {
  name: 'AccordionItem',
  props: {
    header: String,
  },
};
</script>

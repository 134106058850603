<template>
  <div class="template-list">
    <div class="template-list__empty" v-if="!templates.length">
      Нет шаблонов
    </div>
    <div v-else v-for="t in templates" :key="t.id" class="template" @click="$emit('select', t)">
        <div class="template__title">{{t.title}}</div>
        <div class="template__descr" v-if="t.description">{{t.description}}</div>
        <TagList
          class="template__cats"
          :items="allCategories.filter((c) => t.categoryIds.includes(c.id))"
          :item-key="(i) => i.id"
          :item-label="(i) => i.title"
          placeholder="без категории"
          readonly />
        <TagList
          class="template__tags"
          :items="t.tags?.split(/[\r\n]+/)"
          :item-key="(i, idx) => idx"
          :item-label="(i) => i"
          readonly />
        <div class="template__buttons button-list">
          <template v-if="!canEdit || templateToRemove?.id !== t.id">
            <span class="link" :class="{'link_gray': !t.public}" @click.stop="$emit('public', t)" v-if="authStore.user.isAdmin"><GlobeIcon /></span>
            <span class="link" :class="{'link_gray': !t.favorite}" @click.stop="$emit('favorite', t)"><FavoriteIcon /></span>
            <span class="link link_gray" @click.stop="$emit('edit', t)" v-if="canEdit"><EditIcon /></span>
            <span class="link link_gray" @click.stop="templateToRemove = t" v-if="canEdit"><RemoveIcon /></span>
          </template>
          <template v-else>
            <span class="link link_gray" @click.stop="$emit('remove', t)"><ConfirmIcon /></span>
            <span class="link link_gray" @click.stop="templateToRemove = null"><CancelIcon /></span>
          </template>
        </div>
    </div>
  </div>
</template>

<script setup>
import {
  defineProps, defineEmits, ref, watch, computed,
} from 'vue';
import useAuthStore from '@/store/auth';
import useTemplateStore from '@/store/template';
import EditIcon from '@/components/icons/EditIcon.vue';
import RemoveIcon from '@/components/icons/RemoveIcon.vue';
import FavoriteIcon from '@/components/icons/FavoriteIcon.vue';
import ConfirmIcon from '@/components/icons/ConfirmIcon.vue';
import CancelIcon from '@/components/icons/CancelIcon.vue';
import GlobeIcon from '@/components/icons/GlobeIcon.vue';
import TagList from '@/components/TagList.vue';

const props = defineProps({
  templates: Array,
  canEdit: Boolean,
});

defineEmits(['select', 'edit', 'remove', 'favorite', 'public']);

const authStore = useAuthStore();
const templateStore = useTemplateStore();

templateStore.loadCategories(true);

const allCategories = computed(() => templateStore.categories ?? []);

const templateToRemove = ref(null);
watch(props.templates, () => {
  templateToRemove.value = null;
});
</script>

<style lang="scss">
@import "@/assets/variables";

.template{
  background: #f8f8f8;
  padding: 1em;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgba(0,0,0,.15);
  cursor: pointer;
  transition: background-color .3s ease;
  position: relative;
  &__header{
    display: flex;
  }
  &__title{
    font-size: 1.2em;
  }
  &__descr{
    font-size: .9em;
    margin: .75em 0;
    color: #777;
  }
  &__buttons{
    transition: background-color .3s ease;
    position: absolute;
    right: 1em;
    bottom: -1em;
    background: inherit;
    padding: .2em .5em;
    border-radius: 5px;
    box-shadow: 0 5px 5px -2px rgba(0,0,0,.15);
  }
  &__cats, &__tags{
    margin-top: .5em;
    font-size: 13px;
  }
  &__cats {
    .tag{
      background: #c33219;
    }
  }
  &:hover {
    background: #f0f0f0;
  }
}

.template-list{
  display: flex;
  gap: 2em 1em;
  flex-wrap: wrap;
  max-width: $contentNarrowWidth;
  margin: 0 auto;
  &__empty{
    color: #777;
    text-align: center;
    width: 100%;
  }
  .template{
    flex-basis: 100%;
  }
}

@media all and (min-width: 576px) {
  .template-list .template{
    flex-basis: calc(50%  - .5em);
  }
}

@media all and (min-width: 1152px) {
  .template-list .template{
    flex-basis: calc(100% / 3  - 2em / 3);
  }
}
</style>
